<template>
  <v-container fluid class="main-layout">
    <v-row no-gutters>
      <v-col lg="6" v-show="$vuetify.breakpoint.mdAndUp">
        <background-image />
      </v-col>
      <v-col col="6" class="form-container">
        <form class="md-layout complete-profile">
          <div style="width: 70%">
            <v-container>
              <v-row justify="center">
                <v-col justify="center" cols="12">
                  <a href="#/" class="img-style">
                    <img :src="logoImg" width="180px" />
                  </a>
                  <div class="title">Just one more step to go</div>
                </v-col>
              </v-row>
            </v-container>

            <md-card-content class="data-container">
              <div class="md-layout md-gutter">
                <v-col cols="12" md="12" lg="12">
                  <div class="md-layout-item md-small-size-100">
                    <md-field>
                      <md-input
                        name="email"
                        id="email"
                        class="input-data"
                        v-model="model.email"
                        :disabled="true"
                      />
                    </md-field>
                  </div>
                </v-col>
                <v-col cols="12" md="6" lg="6">
                  <div class="md-layout-item md-small-size-100">
                    <md-field :class="getValidationClass('firstName')">
                      <md-input
                        name="first-name"
                        id="first-name"
                        class="input-data"
                        v-model="model.firstName"
                        placeholder="First Name"
                      />
                      <span class="md-error" v-if="firstNameValidationError">{{
                        firstNameValidationError
                      }}</span>
                    </md-field>
                  </div>
                </v-col>
                <v-col cols="12" md="6" lg="6">
                  <div class="md-layout-item md-small-size-100">
                    <md-field :class="getValidationClass('lastName')">
                      <md-input
                        name="last-name"
                        id="last-name"
                        class="input-data"
                        v-model="model.lastName"
                        placeholder="Last Name"
                      />
                      <span class="md-error" v-if="lastNameValidationError">{{
                        lastNameValidationError
                      }}</span>
                    </md-field>
                  </div>
                </v-col>

                <v-col cols="12" md="6" lg="6">
                  <div class="md-layout-item md-small-size-100">
                    <md-field class="field-margin">
                      <label>Account type</label>
                      <md-select
                        name="account-type"
                        v-model="selectedOrgType"
                        placeholder="Account type"
                        class="input input-width"
                      >
                        <div class="account-type-dropdown">
                          <md-option
                            v-for="organizationType in organizationTypes"
                            :key="'organizationType-' + organizationType.id"
                            :value="organizationType.short_code"
                            >{{ organizationType.display_name }}</md-option
                          >
                        </div>
                      </md-select>
                    </md-field>
                  </div>
                </v-col>

                <v-col cols="12" md="6" lg="6">
                  <div class="md-layout-item md-small-size-100">
                    <button
                      style="display: none"
                      id="dummy-button-to-unfocus-dropdown-field"
                    />
                    <md-field
                      id="organization-registry"
                      class="field-margin"
                      :class="getValidationClass('organizationRegistry')"
                    >
                      <md-select
                        v-model="model.organizationRegistry"
                        name="organization-registry"
                        class="input input-width"
                        :placeholder="
                          selectedOrgType == 'brand'
                            ? brandLabel
                            : selectedOrgType == 'supplier'
                            ? supplierLabel
                            : 'Choose Your Business Name'
                        "
                        :disabled="!selectedOrgType"
                      >
                        <v-text-field
                          class="search-width"
                          label="Search"
                          v-on:input="debounceSearch"
                          name="search"
                          v-model="searchName"
                          clearable
                        />
                        <div class="global-dropdown account-type-dropdown">
                          <div style="padding: 0px 16px">
                            <span class="span-label-organization-request">
                              <a
                                href="#"
                                @click.prevent="handleClick"
                                class="clickable-link"
                              >
                                I'm unable to find the business
                              </a>
                            </span>
                          </div>
                          <md-option
                            class="brand-name"
                            v-for="brand in globalBrandList"
                            :key="'brand-' + brand.id"
                            :value="brand.id"
                            ><span class="brand-name">{{ brand.name }}</span>
                          </md-option>
                          <infinite-loading
                            @infinite="fetchMore"
                            :key="`infinite-toggle-${searchName}`"
                          >
                            <div slot="no-more" v-if="globalBrandList.length">
                              No more data
                            </div>
                            <div slot="no-more" v-if="!globalBrandList.length">
                              No data available
                            </div>
                            <div
                              slot="no-results"
                              v-if="globalBrandList.length"
                            >
                              No more data
                            </div>
                            <div
                              slot="no-results"
                              v-if="!globalBrandList.length"
                            >
                              No data available
                            </div>
                          </infinite-loading>
                        </div>
                      </md-select>
                      <span
                        class="md-error"
                        v-if="organizationRegistryValidationError"
                        >{{ organizationRegistryValidationError }}</span
                      >
                    </md-field>
                  </div>
                  <div class="select-account-span-label">
                    <span v-if="!selectedOrgType">
                      Please select an account type
                    </span>
                    <organization-request
                      v-else
                      class="account-request-container"
                      :active="organizationRequesting"
                      @close="organizationRequesting = false"
                    />
                  </div>
                </v-col>

                <v-col cols="12">
                  <div class="terms-style">
                    <v-checkbox
                      v-model="model.terms"
                      :error-messages="checkboxErrors"
                      required
                      @change="$v.model.terms.$touch()"
                      @blur="$v.model.terms.$touch()"
                      color="#473068"
                    >
                      <template v-slot:label>
                        <span id="checkboxLabel">
                          I accept the{{ " " }}
                          <a
                            href="#"
                            @click.stop="openTerms"
                            class="login-link"
                            target="_blank"
                            >terms and conditions</a
                          >,{{ " " }}
                          <a
                            href="#"
                            @click.stop="openCodeOfConduct"
                            class="login-link"
                            target="_blank"
                            >code of conduct</a
                          >{{ " " }}and{{ " " }}
                          <a
                            href="#"
                            @click.stop="openPrivacyPolicy"
                            class="login-link"
                            target="_blank"
                            >privacy policy</a
                          >.
                        </span>
                      </template>
                    </v-checkbox>
                  </div>
                </v-col>
              </div>
            </md-card-content>
            <div style="margin-bottom: 26px">
              <md-button
                @click="save"
                class="wide-button save-btn"
                id="register"
                >Save
              </md-button>
              <md-button @click="backToLogin" class="wide-button cancel-btn"
                >Cancel
              </md-button>
            </div>
          </div>
        </form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import auth from "../../auth";
import restAdapter from "../../restAdapter";
import defaultImage from "../../assets/person.png";
import notification from "../../notification";
import * as conf from "../../conf.yml";
import { maxLength, required } from "vuelidate/lib/validators";
import validationMixin from "../../validation/validation_mixin";
import BackgroundImage from "../../assets/background-images/BackgroundImage.vue";
import debounce from "lodash/debounce";
import InfiniteLoading from "vue-infinite-loading";

/* eslint-disable */
export default {
  name: "CompleteProfile",
  mixins: [validationMixin],
  components: { BackgroundImage, InfiniteLoading },

  data() {
    return {
      baseUrl: "",
      showDialog: false,
      organizationRequesting: false,
      logoImg: require(`../../assets/seamless-source-banner.png`),
      model: {
        email: null,
        firstName: "",
        lastName: "",
        organizationRegistry: null,
        imageUrl: defaultImage,
        terms: false,
        avatar: null,
      },
      organizationTypes: [
        { id: 1, display_name: "Brand", short_code: "brand" },
        { id: 2, display_name: "Supplier", short_code: "supplier" },
      ],
      brandLabel: "Choose Your Business Name",
      supplierLabel: "Choose Your Business Name",
      selectedOrgType: null,
      globalBrandList: [],
      searchName: "",
      currentPage: 1,
    };
  },
  validations: {
    model: {
      firstName: {
        required,
        maxLength: maxLength(150),
      },
      lastName: {
        required,
        maxLength: maxLength(150),
      },
      organizationRegistry: {
        required,
      },
      terms: {
        checked: (value) => value === true,
      },
    },
  },
  watch: {
    selectedOrgType: {
      handler: function (value) {
        (this.model.organizationRegistry = null), (this.searchName = "");
        this.getGlobalBrandList();
      },
      deep: true,
    },
  },
  computed: {
    firstNameValidationError() {
      if (!this.$v.model.firstName.required) {
        return "First name is required";
      }

      if (!this.$v.model.firstName.maxLength) {
        return "First name should less than 150 characters";
      }

      return null;
    },
    lastNameValidationError() {
      if (!this.$v.model.lastName.required) {
        return "Last name is required";
      }

      if (!this.$v.model.lastName.maxLength) {
        return "Last name should less than 150 characters";
      }

      return null;
    },
    organizationRegistryValidationError() {
      if (!this.$v.model.organizationRegistry.required) {
        return "Business name is required";
      }
      return null;
    },
    termsValidationError() {
      if (!this.$v.model.terms.checked && this.$v.model.terms.$dirty) {
        return "Please accept the terms and conditions";
      }

      return null;
    },
    checkboxErrors() {
      const errors = [];
      if (!this.$v.model.terms.$dirty) return errors;
      !this.$v.model.terms.checked &&
        this.save &&
        errors.push("You must accept the terms and conditions");
      return errors;
    },
  },
  methods: {
    openTerms() {
      window.open("https://seamlesssource.com/code-of-conduct-2", "_blank");
    },
    openPrivacyPolicy() {
      window.open("https://seamlesssource.com/privacy-policy", "_blank");
    },
    openCodeOfConduct() {
      window.open("https://seamlesssource.com/code-of-conduct", "_blank");
    },
    getSocialAccountDetails() {
      const socialLoginResponse = JSON.parse(
        window.localStorage.getItem("socialLoginResponse")
      );
      const provider = JSON.parse(window.localStorage.getItem("provider"));
      auth
        .extractSocialDetails(provider, socialLoginResponse)
        .then((response) => {
          if (response.data.already_registered) {
            this.$router.push(this.$route.query.redirect || "/app");
            window.location.reload(true);
          } else {
            const user = response.data.user;
            this.model.firstName = user.first_name;
            this.model.lastName = user.last_name;
            this.model.email = user.email;
          }
        });
    },

    openOrganizationRequest() {
      this.organizationRequesting = true;
    },
    handleClick() {
      document.getElementById("dummy-button-to-unfocus-dropdown-field").click();
      this.openOrganizationRequest();
    },
    debounceSearch: debounce(function () {
      this.getGlobalBrandList();
    }, 600),

    getGlobalBrandList() {
      this.globalBrandList = [];
      this.currentPage = 1;
      let url = "/api/organization_registry";
      url +=
        "?page=" +
        this.currentPage +
        "&type=" +
        this.selectedOrgType +
        "&name=" +
        (this.searchName ? this.searchName : "");
      restAdapter.get(url).then((response) => {
        this.globalBrandList = response.data.data;
      });
    },

    fetchMore($state) {
      let url = "/api/organization_registry";
      url +=
        "?page=" +
        (this.currentPage + 1) +
        "&type=" +
        this.selectedOrgType +
        "&name=" +
        (this.searchName ? this.searchName : "");
      restAdapter.get(url).then((response) => {
        if (response.data.data.length) {
          this.globalBrandList = this.globalBrandList.concat(
            response.data.data
          );
          this.currentPage++;
          $state.loaded();
        } else {
          $state.complete();
        }
      });
    },

    async save() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      const user = {
        ...JSON.parse(window.localStorage.getItem("socialLoginResponse")),
        first_name: this.model.firstName,
        last_name: this.model.lastName,
        organization_registry_id: this.model.organizationRegistry,
        terms_and_conditions: this.model.terms,
      };

      restAdapter
        .post(
          "/api/social/" + JSON.parse(window.localStorage.getItem("provider")),
          user
        )
        .then((response) => {
          window.localStorage.removeItem("provider");
          window.localStorage.removeItem("socialLoginResponse");
          auth.setAuth(response.data.success);
          notification.success("Welcome to " + conf.appName + "!");
          this.$router.push(this.$route.query.redirect || "/app");
          window.location.reload(true);
        })
        .catch((err) => {
          notification.errors(
            err.response.data.error ||
              "Something went wrong, please try again later."
          );
        });
    },
    backToLogin() {
      auth.user.token = null;
      window.localStorage.removeItem("app-user-token");
      window.localStorage.removeItem("provider");
      window.localStorage.removeItem("socialLoginResponse");
      this.$router.push({
        path: `/login`,
      });
    },
  },
  mounted() {
    this.getSocialAccountDetails();
  },
};
</script>
<style lang="scss" scoped>
.main-layout {
  width: 100vw;
  height: 100vh;
  padding: 0 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-style {
  display: flex;
  justify-content: center;
}

.title {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.8px;
  margin-top: 15px;
  text-align: center;
  font-weight: 400;
  font-size: 24px;
  color: $primaryDarkest;
  display: flex;
  justify-content: center;
}

.complete-profile {
  justify-content: center;
  align-items: center;
  max-height: 100vh;
  overflow: auto;
}
.warning-text {
  color: #473068 !important;
}

.md-error {
  color: $danger !important;
}

.email-input {
  background-color: $backgroundColor;
  border-radius: 5px;
  height: 50px;
  align-items: center;
  display: flex;
  margin: 0px -3px;
}

.wide-button {
  width: 100%;
  min-width: 20.188em;
  height: 50px;
  border-radius: 8px;
  margin-top: 10px;
}

.save-btn {
  color: #ffffff !important;
  background: $primaryDark;
  box-shadow: 0px 12px 14px rgba(91, 61, 135, 0.23);
  border-radius: 7px;
}

.cancel-btn {
  background: $paginateText;
  box-shadow: 0px 13.5045px 25.8836px rgba(61, 77, 135, 0.1);
  border-radius: 7px;
  margin-left: 8px !important;
}

.input-data {
  border-radius: 5px;
  border: none !important;
  height: 50px !important;
  padding-left: 12px !important;
  background-color: $backgroundColor !important;
  max-width: 100% !important;
  width: 100%;
  color: $primaryDarkest;
  opacity: 1; /* Firefox */
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 13.9971px !important;
  line-height: 16px;
  letter-spacing: 2px;
}

.md-field.md-theme-default.md-has-value .md-input {
  -webkit-text-fill-color: $primaryDarkest !important;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  // font-size: 13.9971px;
  line-height: 16px;
  letter-spacing: 2px;
}

.md-field.md-theme-default::before {
  background-color: transparent;
}

// .md-field.md-theme-default:after {
//     background-color: transparent !important;
// }

.md-field:after {
  height: 0px !important;
}

.md-field {
  margin: 0px !important;
}

.data-container {
  padding: 0px 16px !important;
}

.field-margin {
  margin-bottom: 7px;
  border-bottom: none !important;
  margin-left: auto;
  margin-right: auto;
}

.input-width {
  width: 100%;
  height: 100%;
  border-bottom: none;
  border: none;
  padding: 10px 20px;
  color: $primaryDarkest;
  opacity: 1; /* Firefox */
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 13.9971px;
  line-height: 16px;
  letter-spacing: 2px;
}

.input-data:focus {
  outline: none;
  border: 2px solid rgba(255, 255, 255, 0.75);
  border-radius: 8px;
  color: $primaryDarkest;
  opacity: 1; /* Firefox */
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
}

.input-data::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $primaryDarkest !important;
  opacity: 1; /* Firefox */
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 13.9971px;
  line-height: 16px;
  letter-spacing: 2px;
}

.md-select::v-deep .md-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $primaryDarkest;
  opacity: 1; /* Firefox */
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 13.9971px;
  line-height: 16px;
  letter-spacing: 2px;
}

.input {
  /* background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0.15)
  ); */
  background-color: $backgroundColor;
  border-radius: 4px;
  height: 50px;
}

.md-selected .md-list-item-content,
.account-type-dropdown::v-deep .router-link-active .md-list-item-content {
  color: $infoMessage;
  background: rgba(243, 244, 248, 0.6);
}

.md-field.md-theme-default label {
  color: transparent !important;
}

.md-menu.md-select {
  overflow: hidden !important;
}

.md-list-item-content {
  color: $primaryDarkest;
}

.md-list-item-content:hover {
  color: $infoMessage;
}

a {
  color: #442d65 !important;
}

.md-list.md-theme-default .md-selected .md-list-item-content {
  color: #473068 !important;
  background-color: #fafbfc !important;
}

.md-dialog-title {
  padding: 24px 100px 0;
}

.title-color {
  width: fit-content;
  color: $primaryDark !important;
  border-bottom: 1px solid $primaryDark;
}
.paragraph-color {
  color: $primaryDark;
  max-width: 100% !important;
}

.ol-style {
  margin-left: 15px;
}
.ol-style-long {
  margin-left: 20px;
}

.md-dialog-actions {
  min-height: 75px !important;
  padding: 35px 115px 30px 24px !important;
}

.md-dialog-content {
  padding: 0 100px 24px;
  line-height: 190%;
}

.close-btn {
  width: 126px;
  height: 32px;
  border: 1px solid $primaryDarkest;
  background: $popupWindow;
  color: $primaryDarkest;
  box-shadow: 6px 29px 22px rgb(62 59 99 / 18%);
  border-radius: 3px !important;
}

.agree-btn {
  width: 126px;
  height: 32px;
  border: 1px solid $primaryDarkest;
  background: $primaryDarkest !important;
  color: $paginateText !important;
  border-radius: 3px !important;
}

.md-dialog {
  background: rgba(15, 5, 29, 0.78);
  backdrop-filter: blur(4px);
}

.brand-name {
  display: block;
  max-width: 240px;
  break-word: anywhere;
  white-space: normal;
}

.link-style {
  color: $linkStyle !important;
}

.md-dialog::v-deep .md-theme-default {
  max-width: 75%;
  max-height: 75%;
  background: $popupWindow;
  border-radius: 8px;
}

#checkboxLabel {
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 1rem !important;
  line-height: 16px !important;
  letter-spacing: 0.5px !important;
  color: $primaryDarkest !important;
  cursor: pointer !important;
}

.login-link {
  color: $primaryDark !important;
  a {
    color: $primaryDark !important;
  }
}

.login-link:hover {
  text-decoration: underline;
}

.email-input-data {
  margin-left: 15px;
  color: $primaryDarkest !important;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  letter-spacing: 2px;
}

.form-container {
  max-height: 100vh;
  max-width: 100%;
  overflow-y: auto;
}

.global-dropdown {
  overflow-y: scroll;
  max-height: 270px;
  text-align: left;
  max-width: 450px;
}

.account-type-dropdown {
  background: rgba(255, 255, 255, 0.75);
  box-shadow: 3.19039px 14.8885px 12.7616px rgba(55, 78, 138, 0.1);
  border-radius: 4px;
}

.account-type-dropdown::v-deep .md-menu-item .md-list-item-button:hover {
  background: $backgroundColor;
}

.md-select::v-deep .md-input,
.md-select::v-deep .md-textarea {
  font-weight: 500 !important;
  font-size: 15px;
  line-height: 16px;
  letter-spacing: 2px;
}

.account-type-dropdown::v-deep .md-selected .md-list-item-content,
.account-type-dropdown::v-deep .router-link-active .md-list-item-content {
  color: $infoMessage;
  background: rgba(243, 244, 248, 0.6);
}

.account-type-dropdown::v-deep .md-list-item-content {
  color: $primaryDarkest;
}

.account-type-dropdown::v-deep .md-list-item-content:hover {
  color: $infoMessage;
}

@media only screen and (max-width: 1280px) {
  .email-input {
    margin: 0px 5px;
  }
}

.terms-style::v-deep .v-messages__message {
  color: $danger !important;
  font-size: 12px;
}

.account-request-container::v-deep {
  .form-container {
    padding: 10px 16% 10px 8% !important;
  }
  .btn-container {
    margin-right: -12% !important;
  }
}
</style>

<style lang="scss">
.md-checkbox.md-theme-default.md-checked .md-checkbox-container {
  background-color: $popupWindow !important;
  border-radius: 4px !important;
}
.md-checkbox.md-theme-default .md-checkbox-container {
  background-color: $popupWindow !important;
  border-color: transparent !important;
  border-radius: 4px !important;
}
.md-checkbox.md-theme-default.md-checked .md-checkbox-container:after {
  border-color: $primaryDarkest !important;
}

.md-checkbox.md-theme-default.md-checked .md-ripple {
  color: #442d64;
}

.select-account-span-label {
  color: $danger !important;
  font-size: 10px;
  margin-top: 16px;
}

.span-label-organization-request {
  font-size: 12px;
  cursor: pointer !important;
}

.search-width {
  padding: 5px 18px 0px;
}
</style>

<style>
.md-menu-content-container {
  overflow: unset !important;
}
</style>
